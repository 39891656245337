import React, { useState, useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

import ChipField from '../../common/ChipField';

const AlertsCountChipField = ({ record, source, ...props }) => {
    let backgroundColor;

    if (!record || !record.last_alerts_count) {
        return null;
    }

    if (record.last_alerts_count > 0) {
        backgroundColor = red[100];
    } else {
        backgroundColor = grey[100];
    }

    return (
        <Tooltip title={record.last_alerts} arrow>
            <span>
                <ChipField source={'last_alerts_count'} record={record} color={backgroundColor} {...props} />
            </span>
        </Tooltip>
    );
};

AlertsCountChipField.defaultProps = {
    source: 'last_alerts_count',
    label: 'Alerts',
    addLabel: true
};

export default AlertsCountChipField;
