import React, { useState, useEffect }  from 'react';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

import ChipField from '../../common/ChipField';

import { fetchStarlinkTerminalStatus } from '../../../services/api';

const statuses = {
    'online': 'ONLINE',
    'offline': 'OFFLINE',
    'unknown': 'UNKNOWN',
    'searching': 'SEARCHING'
};

const StateField = ({ record, source, ...props }) => {
    let backgroundColor;
    const [status, setStatus] = useState();

	useEffect(() => {
		const getStatus = async () => {
            const response = await fetchStarlinkTerminalStatus(record.terminal_id, { source: 'starlink' });
			setStatus(response.state);
		};

		if (record) {
			getStatus();
		}
		else {
			setStatus(record[source]);
		}
	}, [record, source]);

    switch (status) {
        case 'online':
            backgroundColor = green[100];
            break;
        case 'offline':
            backgroundColor = red[100];
            break;
        default:
            backgroundColor = grey[100];
            break;
    }

    if (!record || !status) {
        return null;
    }

    return (
        <ChipField source={status} record={statuses} color={backgroundColor} {...props} />
    );
};

StateField.defaultProps = {
    source: 'state',
    label: 'Status',
    addLabel: true
};

export default StateField;