import React from 'react';
import { useLocale, useTranslate } from 'react-admin';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';

import moment from 'moment';
import 'moment/min/locales';

const TimeStampField = ({
    className,
    source,
    record = {},
    basePath,
    ...rest
}) => {
    const locale = useLocale();
    const translate = useTranslate();
    const timestampValue = moment(get(record, source));

    const momentTimestamp = (ts, timeZone = 'UTC') => {
        // Hack for Vale temporary urgent
        const brusselsTime = moment(ts).subtract(1, 'hours');
        return moment(brusselsTime).local().format('MM/DD/YYYY HH:mm:ss');
      };
    const value = momentTimestamp(timestampValue);

    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
            {...rest}
        >
            {value && typeof value !== 'string' ? JSON.stringify(value) : value}
        </Typography>
    );
};

export default TimeStampField;